<template>
  <div id="requirementsTable">
    <div id="header" class="d-inline-flex w-100 justify-content-between">
      <div class="d-inline-flex">
        <SpecificationSelector />
        <b-button
          v-b-tooltip.hover.top.v-primary="'Refresh'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          size="sm"
          class="btn-icon ml-50"
          @click="onRefreshClicked"
        >
          <feather-icon icon="RefreshCwIcon" />
        </b-button>

        <b-button
          class="d-inline-block h-75 mt-25 ml-50"
          variant="outline-primary"
          size="sm"
          @click="$bvModal.show('show-hide-columns-modal')"
        >
          <feather-icon icon="ColumnsIcon" />
          Show/Hide Columns
        </b-button>
        <!--<button class="btn btn-outline-dark" @click="scrollToRequirementId">-->
        <!--  Go to Requirement-->
        <!--</button>-->
      </div>

      <b-button-group class="ml-50">
        <b-dropdown
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
        >
          <template v-slot:button-content>
            <feather-icon icon="MenuIcon" class="mr-25" />
            Menu
          </template>
          <b-dropdown-item @click="$bvModal.show('section-navigation-modal')">
            Go-to Section
          </b-dropdown-item>
          <b-dropdown-item @click="$bvModal.show('show-hide-columns-modal')">
            Show / Hide Columns
          </b-dropdown-item>
          <!--<b-dropdown-item v-if="mode === 'document'" @click="setMode({ mode:'backlog'})">-->
          <!--  Switch to Backlog Mode-->
          <!--</b-dropdown-item>-->
          <!--<b-dropdown-item v-else-if="mode === 'backlog' || mode === 'snapshot_compare'" @click="setMode({ mode: 'document' })">-->
          <!--  Switch to Document Mode-->
          <!--</b-dropdown-item>-->
          <b-dropdown-divider />
          <b-dropdown-item v-if="selectedRowIndexes.length > 1" @click="$bvModal.show('bulk-update-priority')">
            Bulk Update Priority
          </b-dropdown-item>
          <b-dropdown-item v-if="selectedRowIndexes.length > 1" @click="$bvModal.show('bulk-update-verification_method')">
            Bulk Update Verification Methods
          </b-dropdown-item>
          <!--<b-dropdown-item @click="$bvModal.show('snapshot-compare-modal')">-->
          <!--  Compare Snapshot-->
          <!--</b-dropdown-item>-->
          <b-dropdown-divider />
          <b-dropdown-item @click="$bvModal.show('import-requirements-modal')">
            Import Requirements from CSV
          </b-dropdown-item>
          <b-dropdown-item @click="$bvModal.show('export-requirements-modal')">
            Export Requirements
          </b-dropdown-item>
          <b-dropdown-item @click="$bvModal.show('trace-export-modal')">
            Export Trace
          </b-dropdown-item>
          <b-dropdown-divider />
          <!--<b-dropdown-item @click="$store.commit('requirementsTableLegacy/SET_SHOW_DELETED', !show_deleted)">-->
          <!--  <span class="mt-1" style="display: inline-block">-->
          <!--    <b-checkbox-->
          <!--      :checked="show_deleted"-->
          <!--      switch-->
          <!--      style="display: inline"-->
          <!--      @change="$store.commit('requirementsTableLegacy/SET_SHOW_DELETED', !show_deleted)"-->
          <!--    >-->
          <!--      Show Deleted Requirements-->
          <!--    </b-checkbox>-->
          <!--  </span>-->
          <!--</b-dropdown-item>-->
        </b-dropdown>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.top.v-success="'Create a new Requirement (Shortcut: Insert)'"
          variant="outline-success"
          @click="$bvModal.show('create-requirement-modal')"
        >
          <feather-icon icon="PlusIcon" />
          Create
        </b-button>

        <b-button
          v-if="selectedRowIndexes.length > 0"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.top.v-primary="'Open requirement details'"
          variant="primary"
          @click="() => { showDetailsSidebar = !showDetailsSidebar }"
        >
          <feather-icon icon="SidebarIcon" class="mr-25" />
          {{ showDetailsSidebar ? 'Hide' : 'Show' }} Details
        </b-button>
        <b-button
          v-if="selectedRowIndexes.length > 0"
          v-b-tooltip.hover.top.v-danger="'Clear selection (Shortcut: Esc)'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-danger"
          class="btn-icon"
          @click="onClearClicked"
        >
          <feather-icon icon="SlashIcon" />
        </b-button>
      </b-button-group>
    </div>

    <!-- Requirements Table -->
    <b-card v-if="allRequirements && allRequirements.length" id="table" no-body class="mt-1">
      <div v-for="(req, index) in allRequirements" :key="index">
        <div
          :id="req.id"
          :data-index="index"
          :data-display-id="req.display_id"
          :data-priority="req.priority"
          :data-section="req.section"
          class="lazy"
          @click="onRowClicked"
          @contextmenu.prevent="event => onOpenContextMenu(event)"
        >
          <div
            v-if="req.showItem"
            class="req-row"
            draggable="true"
          >
            <!-- Top (Key Details) Row -->
            <div class="top-row d-inline-flex w-100 justify-content-between">
              <div class="d-inline-flex w-100">
                <p v-if="isColumnShowing('section')" class="req-section-label">
                  {{ req.section }}
                </p>
                <p v-if="isColumnShowing('display_id')" class="text-primary text-nowrap mr-50 font-weight-bold">
                  {{ req.display_id }}
                </p>
                <p v-if="isColumnShowing('priority')" class="text-nowrap mr-50 text-muted">
                  [ {{ req.priority }} ]
                </p>
              </div>
              <div v-if="isColumnShowing('classification')">
                <p class="font-weight-bold text-nowrap my-0">
                  {{ req.classification }}
                </p>
              </div>
            </div>

            <b-table
              v-if="tableColumns.length > 0"
              :items="[req]"
              :fields="tableColumns"
              class="mt-0 pt-0"
              thead-tr-class="d-none"
              tbody-tr-class="align-top"
              table-class="bg-transparent"
              borderless
              fixed
              small
            >
              <!-- Default row style -->
              <template #cell()="data">
                <div class="flex-column align-items-end w-100 h-100">
                  <p class="text-muted mb-0">
                    {{ data.field.label }}:
                  </p>
                  <p :class="`${data.value ? '' : 'text-muted ml-25'}`">
                    {{ data.value ? data.value : '(no value)' | informalDate }}
                  </p>
                </div>
              </template>
              <!-- ./Default row style -->

              <!-- Object Text Row -->
              <template #cell(object_text)="{ item }">
                <p
                  v-if="item.object_text"
                  class="overflow-hidden"
                  :style="`margin-left: ${item.section ? item.section.split('.').length : 0}rem`"
                  v-sanitized-html="item.object_text"
                />
                <p
                  v-else
                  class="text-muted overflow-hidden"
                  :style="`margin-left: ${item.section ? item.section.split('.').length : 0}rem`"
                >
                  (empty requirement)
                </p>
              </template>

              <template #cell(tests)="{ item }">
                <list-test-cases :all-tests="item.tests" />
              </template>

              <template #cell(issues)="{ item }">
                <list-issues :all-issues="item.issues" />
              </template>

              <template #cell(behaviours)="{ item }">
                <list-behaviour-nodes :all-behaviour-nodes="item.behaviours" />
              </template>

              <template #cell(releases)="{ item }">
                <list-releases :all-releases="item.releases" />
              </template>

              <template #cell(interfaces)="{ item }">
                <list-interfaces :all-interfaces="item.interfaces" />
              </template>

              <template #cell(components)="{ item }">
                <list-components :all-components="item.components" />
              </template>

              <template #cell(trace)="{ item }">
                <list-trace :all-trace-items="item.trace" />
              </template>

              <template #cell(qualification_records)="{ item }">
                <list-qualification-records :parent-id="item.id" :all-qualification-records="item.qualification_records" />
              </template>

              <template #cell(coverage)="{ item }">
                <list-trace is-forward-trace :all-trace-items="item.coverage" />
              </template>

              <template #cell(notes)="{ item }">
                <list-notes :all-notes="item.notes" read-only />
              </template>

              <template #cell(created_by)="{ item }">
                {{ getUserUsername(item.created_by) }}
              </template>

              <template #cell(updated_by)="{ item }">
                {{ getUserUsername(item.updated_by) }}
              </template>
            </b-table>
          </div>
          <div v-else class="req-row">
            {{ req.display_id }}
          </div>
        </div>
      </div>
    </b-card>
    <b-card v-else id="emptyTable" class="mt-1">
      <p class="mb-0">
        This specification contains no requirements...
      </p>
    </b-card>
    <!-- ./requirements table -->

    <!-- Loader / End of Document -->
    <div v-if="loadingRequirements" class="w-100 d-inline-flex justify-content-center align-items-center animate-pulse" style="min-height: 10vh !important;">
      <span class="text-primary font-weight-bold mr-1">
        Loading...
      </span>
      <looping-rhombuses-spinner />
    </div>
    <div class="w-100 d-inline-flex justify-content-center align-items-center" style="min-height: 5vh !important;">
      <span class="text-muted font-weight-bold mr-1">
        End of specification.
      </span>
    </div>

    <RequirementContextSidebar
      v-if="selectedRowIndexes.length === 1"
      :selected-requirement="$store.getters['requirementsTable/resolveRequirementObjects'](selectedRowIndexes)[0]"
      :visible="showDetailsSidebar"
      @update:visible="showDetailsSidebar = $event"
    />

    <!--<BulkRequirementContextSidebar-->
    <!--  v-else-if="selectedRowIndexes.length > 1"-->
    <!--  :selected-requirement-ids="resolveRequirementIds(selectedRowIndexes)"-->
    <!--  :visible="showDetailsSidebar"-->
    <!--  @update:visible="showDetailsSidebar = $event"-->
    <!--/>-->

    <ScrollToTopButton />
    <OpenContextSidebarButton
      v-if="selectedRowIndexes.length === 1"
      @on-click="() => { showDetailsSidebar = !showDetailsSidebar }"
    />

    <div id="modals">
      <show-hide-columns-modal @on-update="onRefreshClicked" />
      <create-requirement-modal @on-submit="onRefreshClicked" />
      <update-requirement-modal
        v-if="selectedRowIndexes.length === 1"
        :selected-requirement="$store.getters['requirementsTable/resolveRequirementObjects'](selectedRowIndexes)[0]"
        @on-submit="onRefreshClicked"
      />
      <delete-requirement-modal
        :selected-requirement-objs="$store.getters['requirementsTable/resolveRequirementObjects'](selectedRowIndexes)"
        @on-submit="onRefreshClicked"
      />
      <link-backward-trace :requirement-ids="$store.getters['requirementsTable/resolveRequirementIds'](selectedRowIndexes)" />
      <link-forward-trace :requirement-ids="$store.getters['requirementsTable/resolveRequirementIds'](selectedRowIndexes)" />
      <link-interfaces :requirement-ids="$store.getters['requirementsTable/resolveRequirementIds'](selectedRowIndexes)" />
      <link-issues :requirement-ids="$store.getters['requirementsTable/resolveRequirementIds'](selectedRowIndexes)" />
      <link-test-cases :requirement-ids="$store.getters['requirementsTable/resolveRequirementIds'](selectedRowIndexes)" />
      <link-releases :requirement-ids="$store.getters['requirementsTable/resolveRequirementIds'](selectedRowIndexes)" />

      <!-- Legacy -->
      <import-csv-requirements-modal />
      <export-requirements-modal />
      <trace-export-modal />
    </div>

    <RequirementContextMenu
      ref="reqContextMenu"
      :selected-requirement-objs="$store.getters['requirementsTable/resolveRequirementObjects'](selectedRowIndexes)"
      @toggle-context-sidebar="showDetailsSidebar = !showDetailsSidebar"
    />
  </div>
</template>

<script>
import store from '@/store'
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import { BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// Global Modals
import ImportCsvRequirementsModal from '@/views/RequirementsTableLegacy/modals/Import.vue'
import TraceExportModal from '@/views/RequirementsTableLegacy/modals/TraceExportModal.vue'

// Global Components
import ListNotes from '@/components/Notes/ListNotes.vue'

// Utilities
import LoopingRhombusesSpinner from '@/components/Spinners/LoopingRhombusesSpinner.vue'
import ScrollToTopButton from '@/components/Generic/ScrollToTopButton.vue'
import utilsLazyLoad from './utils-lazyLoad'
import utilsRowSelection from './utils-rowSelection'

// Components
import SpecificationSelector from './components/SpecificationSelector.vue'
import RequirementContextSidebar from './components/RequirementContextSidebar.vue'
import OpenContextSidebarButton from './components/OpenContextSidebarButton.vue'
import RequirementContextMenu from './components/RequirementContextMenu.vue'

// List Groups
import ListBehaviourNodes from './components/ListBehaviourNodes.vue'
import ListComponents from './components/ListComponents.vue'
import ListIssues from './components/ListIssues.vue'
import ListInterfaces from './components/ListInterfaces.vue'
import ListQualificationRecords from './components/ListQualificationRecords.vue'
import ListReleases from './components/ListReleases.vue'
import ListTestCases from './components/ListTestCases.vue'
import ListTrace from './components/ListTrace.vue'

// Modals
import ShowHideColumnsModal from './modals/ShowHideColumns.vue'
import CreateRequirementModal from './modals/CreateRequirement.vue'
import ExportRequirementsModal from './modals/ExportRequirements.vue'
import UpdateRequirementModal from './modals/UpdateRequirement.vue'
import DeleteRequirementModal from './modals/DeleteRequirement.vue'
import LinkInterfaces from './modals/LinkInterfaces.vue'
import LinkIssues from './modals/LinkIssues.vue'
import LinkTestCases from './modals/LinkTestCases.vue'
import LinkReleases from './modals/LinkReleases.vue'
import LinkBackwardTrace from './modals/LinkBackwardTrace.vue'
import LinkForwardTrace from './modals/LinkForwardTrace.vue'

export default {
  name: 'RequirementsTable',
  directives: { Ripple },
  components: {
    BTable,
    ScrollToTopButton,
    LoopingRhombusesSpinner,
    RequirementContextSidebar,
    RequirementContextMenu,
    OpenContextSidebarButton,
    SpecificationSelector,
    ListInterfaces,
    ListIssues,
    ListTestCases,
    ListTrace,
    ListQualificationRecords,
    ListBehaviourNodes,
    ListComponents,
    ShowHideColumnsModal,
    CreateRequirementModal,
    UpdateRequirementModal,
    DeleteRequirementModal,
    TraceExportModal,
    ImportCsvRequirementsModal,
    ExportRequirementsModal,
    LinkInterfaces,
    LinkIssues,
    ListReleases,
    LinkTestCases,
    LinkBackwardTrace,
    LinkForwardTrace,
    LinkReleases,
    ListNotes,
  },
  setup(props, context) {
    onMounted(() => {
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
      // Load event listeners
      loadRowSelectionEventListeners()
      window.addEventListener('keydown', handleKeyDown)

      // Get the Requirements
      fetchAllRequirements()
    })
    onUnmounted(() => {
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
      // Unload event listeners
      unloadRowSelectionEventListeners()
      store.dispatch('requirementsTable/clearRequirementsTableStore')
    })

    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)
    const routeQuery = computed(() => route.value.query)

    /**
     * When the Specification is changed:
     */
    const routeSpecId = computed(() => routeParams.value.specId)
    watch(routeSpecId, () => { handleSpecificationChanged() })
    const handleSpecificationChanged = () => {
      // Reset row selection and styling
      clearSelectedRequirements()
      resetRowHeights()

      // Fetch the requirements
      fetchAllRequirements()
    }

    /**
     * Row selection
     */
    const selectedRowIndexes = ref([])
    const {
      handleSelection,
      clearSelectedRequirements,
      loadRowSelectionEventListeners,
      unloadRowSelectionEventListeners,
      handleRouteQueryOnRowSelection,
    } = utilsRowSelection(selectedRowIndexes)

    /**
     * Lazy loading
     */
    const { resetRowHeights } = utilsLazyLoad()

    /**
     * Fetching all data
     */
    const allRequirements = computed(() => store.state.requirementsTable.allRequirements)
    const loadingRequirements = computed(() => store.state.requirementsTable.loadingRequirements)
    const isColumnShowing = computed(() => store.getters['requirementsTable/isColumnShowing'])
    const fetchAllRequirements = () => {
      store.dispatch('requirementsTable/getAllRequirements')
    }
    const tableColumns = computed(() => {
      const filteredColumns = store.state.requirementsTable.columns.filter(item => !['section', 'display_id', 'priority', 'classification'].includes(item.value))
      return filteredColumns.map(item => ({ key: item.value }))
    })

    const onRefreshClicked = () => {
      clearSelectedRequirements()
      handleRouteQueryOnRowSelection()
      resetRowHeights()
      fetchAllRequirements()
    }

    /**
     * UI Button Handlers
     */
    const onRowClicked = event => {
      handleSelection(event)
      handleRouteQueryOnRowSelection()
    }
    const onClearClicked = event => {
      // Clear selectedRowIndexes in Vuex store and selected styling
      clearSelectedRequirements()
      handleRouteQueryOnRowSelection()
    }

    const reqContextMenu = ref(null)
    const onOpenContextMenu = event => {
      onRowClicked(event)
      reqContextMenu.value.menu.open(event)
    }

    // Requirement Details (Context) Sidebar
    const showDetailsSidebar = ref(false)

    /**
     * TODO
     * Scroll-to behaviour
     */
    const scrollToRequirementId = () => {
      const element = document.querySelector('[data-section="1.7.14.2"]')

      if (element) {
        // Scroll to the calculated position
        element.scrollIntoView({
          block: 'start',
        })
        const halfWindowHeight = window.innerHeight / 2
        element.scrollBy(halfWindowHeight, 0)
      }
      router
        .push({ query: { ...routeQuery.value, selectedRequirement: '525176c0-4778-49cd-b4d6-8cb18b4bb7ee' } })
        .catch(err => { if (err.name !== 'NavigationDuplicated') throw err })
    }

    const handleKeyDown = event => {
      if (event.altKey && event.key === 'r') {
        // Refresh the requirements table (Alt + R)
        onRefreshClicked()
      }
      if (event.key === 'Insert') {
        context.root.$bvModal.show('create-requirement-modal')
      }
      // if (event.key === 'Delete' && selectedRowIndexes.value.length > 0) {
      //   context.root.$bvModal.show('delete-requirement-modal')
      // }
    }

    return {
      allRequirements,
      loadingRequirements,
      tableColumns,
      selectedRowIndexes,
      onRefreshClicked,
      onClearClicked,

      // Right-click Context Menu
      onOpenContextMenu,
      reqContextMenu,

      isColumnShowing,

      routeQuery,
      onRowClicked,

      showDetailsSidebar,

      scrollToRequirementId,
    }
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

.req-row {
  display: grid;
  min-height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgba(166, 197, 226, 0.16);
  padding: 0.5rem 1rem 0;
  width: 100% !important;
}

.selected {
  background-color: rgba(115, 103, 240, 0.2) !important;
  color: white !important;
  border: 1px solid rgba(115, 103, 240, 0.5);
}

.draggedOver {
  position: relative;
}

.topLeft {
  border-top: 3px dashed $primary;
}

.bottomLeft {
  border-bottom: 3px dashed $primary;
}

// (Currently unused, may be used in the future)
//.topRight::before {
//  content: '';
//  position: absolute;
//  top: 0;
//  right: 0;
//  width: 85%; /* Adjust this value to control the width of the "half-border" */
//  height: 0;
//  border-top: 3px dashed $primary;
//}

.bottomRight::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 85%; /* Adjust this value to control the width of the "half-border" */
  border-bottom: 3px dashed $primary;
}

div[data-priority="Heading"] {
  background-color: rgba(243, 242, 247, 1);
  font-weight: bold;
  text-decoration-color: #455A64 !important;
  text-decoration-thickness: 0.1rem;
  color: #455A64;
}

.dark-layout {
  div[data-priority="Heading"] {
    background-color: rgba(#343d55, 1);
    text-decoration-color: rgba(255, 255, 255, 0.5) !important;
    color: white !important;
  }
}

.req-section-label {
  font-weight: 500;
  white-space: nowrap !important;
  min-width: 4rem;
  padding-right: 1rem;
}

.align-top td {
  vertical-align: top;
}

</style>
